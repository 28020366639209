import { createContext, useState, useContext, useCallback } from "react";
import {
  getSubscriptionAPICall,
  subscribeToOfferAPICall,
  cancelSubscriptionAPICall,
  getDashboardURLAPICall,
  getNumberOfConfirmedUsersAPICall,
} from "../api/licensing/licensingService";

export const LicensingContext = createContext({
  subscribeToOffer: [],
  subscription: [],
  cancelSubscription: () => {},
  subscriptionDetails: () => {},
  dashboardURL: () => {},
  setSubscription: () => {},
  numberOfConfirmedUsers: () => {},
});

const LicensingContextProvider = ({ children, setLoading }) => {
  const [subscription, setSubscription] = useState(null);

  const subscribeToOffer = useCallback(
    async (projectId, offerName, onSuccess, onError) => {
      subscribeToOfferAPICall(projectId, offerName, onSuccess, onError);
    }
  );

  const cancelSubscription = useCallback(
    async (projectId, onSuccess, onError) => {
      cancelSubscriptionAPICall(projectId, onSuccess, onError);
    }
  );

  const subscriptionDetails = useCallback(
    async (projectId, onSuccess, onError) => {
      getSubscriptionAPICall(projectId, onSuccess, onError);
    }
  );

  const numberOfConfirmedUsers = useCallback(
    async (projectId, onSuccess, onError) => {
      getNumberOfConfirmedUsersAPICall(projectId, onSuccess, onError);
    }
  );

  const dashboardURL = useCallback(async (projectId, onSuccess, onError) => {
    getDashboardURLAPICall(projectId, onSuccess, onError);
  });

  return (
    <LicensingContext.Provider
      value={{
        subscribeToOffer,
        cancelSubscription,
        subscriptionDetails,
        dashboardURL,
        subscription,
        setSubscription,
        numberOfConfirmedUsers,
      }}
    >
      {children}
    </LicensingContext.Provider>
  );
};

export const useLicensingContext = () => useContext(LicensingContext);

export default LicensingContextProvider;
