import { DELETE, GET, PATCH, POST } from "../API";

export const getSubscriptionAPICall = (projectId, onSuccess, onError) => {
  GET(
    process.env.REACT_APP_API_PATH +
      `/public_api/projects/${projectId}/licensing/subscription`,
    onSuccess,
    onError
  );
};

export const getNumberOfConfirmedUsersAPICall = (
  projectId,
  onSuccess,
  onError
) => {
  GET(
    process.env.REACT_APP_API_PATH +
      `/public_api/projects/${projectId}/licensing/confirmed_users`,
    onSuccess,
    onError
  );
};

export const subscribeToOfferAPICall = (
  projectId,
  offerName,
  onSuccess,
  onError
) => {
  const data = {
    name: offerName,
  };

  POST(
    process.env.REACT_APP_API_PATH +
      `/public_api/projects/${projectId}/licensing/subscribe_to_offer`,
    data,
    onSuccess,
    onError
  );
};

export const cancelSubscriptionAPICall = (projectId, onSuccess, onError) => {
  DELETE(
    process.env.REACT_APP_API_PATH +
      `/public_api/projects/${projectId}/licensing/cancel_subscription`,
    onSuccess,
    onError
  );
};

export const getDashboardURLAPICall = (projectId, onSuccess, onError) => {
  GET(
    process.env.REACT_APP_API_PATH +
      `/public_api/projects/${projectId}/licensing/dashboard_url`,
    onSuccess,
    onError
  );
};
